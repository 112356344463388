<template>
    <div class="c-dock" @click="onDockClick()">
        <div class="c-dock__battery" ref="batteryDiv" :style="'color:' + this.batteryColor">
            <h1>{{ batteryLevel }}%</h1>
            <div>click to start</div>
        </div>
    </div>
</template>

<script>
export default {
  mounted () {
    this.$xp.screen.setOrientation('landscape')
    this.statusPositionTimeout = setInterval(() => {
      this.changePosition()
    }, 3000)
    window.localStorage.removeItem('nfcLangCode')
    window.localStorage.removeItem('nfcSelectedBranchIds')
    this.sleepOrDont()
  },
  data () {
    return {
      statusPositionTimeout: null,
      sleepTimeout: null
    }
  },
  computed: {
    batteryLevel () {
      return this.$xp.battery.level
    },
    batteryColor () {
      const r = this.batteryLevel < 50 ? 255 : Math.floor(255 - (this.batteryLevel * 2 - 100) * 255 / 100)
      const g = this.batteryLevel > 50 ? 255 : Math.floor((this.batteryLevel * 2) * 255 / 100)
      return 'rgb(' + r + ',' + g + ',0)'
    }
  },
  methods: {
    changePosition () {
      const leftRange = window.innerWidth - this.$refs.batteryDiv.offsetWidth
      const left = (Math.random() * leftRange * 0.70) + leftRange * 0.3
      const topRange = window.innerHeight - this.$refs.batteryDiv.offsetHeight
      const top = Math.random() * (topRange * 0.70)
      this.$refs.batteryDiv.style.left = left + 'px'
      this.$refs.batteryDiv.style.top = top + 'px'
    },
    onDockClick () {
      this.$router.replace('/')
    },
    sleepOrDont () {
      if (this.batteryLevel > this.$xp.settings.mergedSettings.battery.dockSleepLevel) {
        this.$xp.screen.setSleepTimeOut(this.$xp.settings.mergedSettings.screen.standbySeconds * 1000)
      } else {
        this.$xp.screen.preventSleep()
      }
    }
  },
  watch: {
    batteryLevel () {
      this.sleepOrDont()
    }
  },
  beforeDestroy () {
    this.$xp.screen.preventSleep()
    clearInterval(this.statusPositionTimeout)
  }
}
</script>
